.home-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
  }

  .footer {
    background: linear-gradient(to right, black, #3498db);
    color: #fff;
    padding: 2rem 1rem;
    font-family: "Roboto", sans-serif;
  }
  
  .footer__separator {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 1rem 0;
  }
  
  .footer__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .footer__logo img {
    max-width: 180px;
    height: auto;
  }
  
  .footer__nav {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .footer__nav-link {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .footer__nav-link:hover {
    color: #f2c94c;
  }
  
  .footer__middle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .footer__contact {
    margin-bottom: 1rem;
  }
  
  .footer__contact-item {
    margin: 0.5rem 0;
  }
  
  .footer__phone {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footer__bottom {
    text-align: center;
  }
  
  .footer__copyright {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
  }
  .home-footer {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-image {
    width: 200px;
    object-fit: cover;
  }
  .home-nav {
    flex: 0 0 auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: row;
  }
  .home-text {
    margin-left: 0px;
  }
  .home-text1 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-text2 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-text3 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-text4 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .home-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon2 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .home-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon-group1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon4 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }

  @media (max-width: 768px) {
    .footer__top,
    .footer__middle {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer__nav {
      margin-top: 1rem;
      /* Center nav if you want: 
         align-items: center; 
         justify-content: center; 
      */
    }
  }
  /* @media(max-width: 1200px) {
    .home-footer {
      position: relative;
    }
    .home-container3 {
      flex: 1;
      margin-top: var(--dl-space-space-twounits);
    }
    .home-text6 {
      box-shadow: inherit;
      text-transform: inherit;
      background-color: inherit;
    }
    .home-text7 {
      box-shadow: inherit;
      text-transform: inherit;
      background-color: inherit;
    }
  }
  @media(max-width: 767px) {
    .home-footer {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-separator {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container2 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text5 {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container3 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text6 {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-text7 {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }
  @media(max-width: 479px) {
    .home-footer {
      padding: var(--dl-space-space-unit);
    }
    .home-separator {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container2 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text5 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container3 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 50px;
    }
    .home-text6 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-text7 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }

  @media(max-width: 280px) {
    .home-footer {
      padding: var(--dl-space-space-unit);
    }
    .home-separator {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container2 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text5 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container3 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text6 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-text7 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
  } */
  