.vimeo-live-embed-container {
    padding: 20px;
  }
  
  .subscription-section {
    margin-bottom: 100px;
  }
  
  .subscription-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .subscription-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .video-container {
    margin-top: 20px;
  }

  /* .video-container iframe {
    transform: scaleX(-1);
    transform-origin: center;
  } */
  
  .loading-spinner {
    margin-top: 20px;
  }
  