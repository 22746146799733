.red-button {
    position: relative;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    transition: opacity 0.5s ease;
    margin-bottom: 20px;
    animation-name: pulse;
    animation-delay: 0s;
    animation-duration: 500ms;
    animation-direction: normal;
    animation-iteration-count: 10000000;
    animation-timing-function: ease;
}

.red-button.hidden {
    opacity: 0;
}

.red-button::before,
.red-button::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0; 
    height: 0; 
    border-style: solid;
    transform: translateY(-50%);
}

.red-button::before {
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent red;
    left: -20px;
}

.red-button::after {
    border-width: 10px 10px 10px 0;
    border-color: transparent red transparent transparent;
    right: -20px;
}

.red-button:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    opacity: 1;
}