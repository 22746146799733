.irodakozelites-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.irodakozelites-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 1000px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.irodakozelites-text {
  font-size: 30px;
  text-align: center;
}
.irodakozelites-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.irodakozelites-container {
  display: flex;
  align-items: flex-start;
}
.irodakozelites-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-unit);
}
.irodakozelites-text1 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
}
.irodakozelites-icon2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-unit);
}
.irodakozelites-text2 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
@media(max-width: 767px) {
  .irodakozelites-container {
    display: contents;
  }
  .irodakozelites-icon {
    width: var(--dl-size-size-small);
    height: 24px;
    margin-right: 0px;
  }
  .irodakozelites-text1 {
    margin-right: 0px;
  }
  .irodakozelites-icon2 {
    width: var(--dl-size-size-small);
    height: 24px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .irodakozelites-max-width {
    width: 320px;
  }
  .irodakozelites-content {
    width: 320px;
    margin-right: 0px;
  }
  .irodakozelites-text {
    width: 317px;
  }
  .irodakozelites-actions {
    width: 100%;
    flex-direction: initial;
  }
  .irodakozelites-container {
    width: 100%;
  }
  .irodakozelites-icon {
    width: var(--dl-size-size-small);
  }
  .irodakozelites-text1 {
    font-size: 20px;
  }
  .irodakozelites-icon2 {
    width: var(--dl-size-size-small);
  }
  .irodakozelites-text2 {
    font-size: 20px;
  }
}
