.motto-max-width {
  align-self: center;
}
.motto-hero-image {
  width: 100%;
  height: auto;
  max-width: 550px;
  border-radius: var(--dl-radius-radius-radius4);
}
.motto-column {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.motto-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.motto-text {
  font-size: 25px;
}
.motto-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
@media(max-width: 1600px) {
  .motto-max-width {
    width: 1201px;
  }
  .motto-content {
    width: 664px;
  }
  .motto-text {
    width: 666px;
  }
  .motto-actions {
    width: 100%;
    height: 121px;
    flex-wrap: wrap;
  }
}
@media(max-width: 1200px) {
  .motto-max-width {
    width: 988px;
  }
  .motto-column {
    width: 448px;
  }
  .motto-content {
    width: 429px;
  }
  .motto-text {
    width: 435px;
  }
  .motto-actions {
    width: 442px;
    height: 109px;
    flex-wrap: wrap;
  }
}
@media(max-width: 991px) {
  .motto-max-width {
    width: 767px;
    flex-direction: column;
  }
  .motto-hero-image {
    width: 100%;
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .motto-max-width {
    width: 487px;
  }
  .motto-column {
    width: 100%;
  }
  .motto-content {
    width: 100%;
  }
  .motto-text {
    width: 483px;
  }
  .motto-actions {
    width: 487px;
    height: 99px;
    flex-wrap: wrap;
  }
}
@media(max-width: 479px) {
  .motto-max-width {
    width: 320px;
  }
  .motto-column {
    width: 100%;
  }
  .motto-content {
    width: 100%;
  }
  .motto-text {
    width: 320px;
    font-size: 30px;
    text-align: center;
  }
  .motto-actions {
    width: 100%;
    height: 142px;
    display: block;
  }
  .motto-button {
    width: 100%;
  }
  .motto-button1 {
    width: 100%;
  }
}
