.red-button2 {
    position: relative;
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: opacity 0.5s ease;
    margin-bottom: 20px;
}

.red-button2.hidden {
    opacity: 0;
}

.red-button2::before,
.red-button2::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0; 
    height: 0; 
    border-style: solid;
    transform: translateY(-50%);
}

.red-button2::before {
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent red;
    left: -20px;
}

.red-button2::after {
    border-width: 10px 10px 10px 0;
    border-color: transparent red transparent transparent;
    right: -20px;
}

.red-button2:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    opacity: 1;
}