.OddEvenCalendar {
    text-align: center;
    padding: 1rem;
  }
  
  .day {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  h2 {
    margin-bottom: 1rem;
  }
  