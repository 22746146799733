.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .login-page-container {
    margin: 0 auto;
    height: fit-content;
  }

  .login-card {
    border: 1px solid #ddd;
    border-radius: 6px;
  }

  .login-card h2 {
    font-weight: 600;
  }
  
  .card {
    margin-top: auto;
    width: 100%;
    max-width: 400px;
    padding: 20px;
  }
  
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .title2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: revert;
    text-shadow: 0 0 5px black;
    text-transform: uppercase;
  }
  
  .sub-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 3rem;
  }
  
  .error-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(220, 53, 69, 0.9);
    color: white;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 5s ease-in-out forwards;
  }

  .success-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(40, 167, 69, 0.9);
    color: white;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 5s ease-in-out forwards;
  }
  
  .popup-content {
    font-size: 1rem;
    text-align: center;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  