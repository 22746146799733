.turelmesek-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.turelmesek-column {
  gap: 24px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.turelmesek-content {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.turelmesek-heading1 {
  font-size: 30px;
}
.turelmesek-heading2 {
  font-size: 30px;
}
.turelmesek-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.turelmesek-container1 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 35%;
  justify-content: flex-start;
}
.turelmesek-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  flex-grow: 1;
  object-fit: cover;
}
@media(max-width: 991px) {
  .turelmesek-container {
    flex-direction: column;
  }
  .turelmesek-container1 {
    width: 100%;
    padding-top: 56.25%;
  }
}
@media(max-width: 767px) {
  .turelmesek-heading1 {
    text-align: center;
  }
  .turelmesek-heading2 {
    text-align: center;
  }
  .turelmesek-actions {
    width: 100%;
    justify-content: center;
  }
  .turelmesek-image1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .turelmesek-actions {
    flex-direction: column;
  }
}
