.navbar-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-navbar-interactive {
  fill: var(--dl-color-theme-neutral-dark);
  color: #ffffff;
  width: 100%;
  display: flex;
  max-width: idth;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgba(0, 80, 161, 0.9) 66.00%);
}
.navbar-image {
  width: 200px;
  object-fit: cover;
}
.navbar-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.navbar-desktop-menu {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.navbar-links {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-icon {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  margin-left: 0px;
}
.navbar-icon02 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: center;
  margin-left: -5px;
  margin-right: -5px;
}
.navbar-link2 {
  align-self: center;
  margin-left: -5px;
}
.navbar-icon04 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-link3 {
  margin-right: var(--dl-space-space-unit);
}
.navbar-icon06 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  margin-left: -15px;
  margin-right: mixed;
}
.navbar-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-text {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: -15px;
  margin-right: mixed;
  vertical-align: middle;
}
.navbar-dropdown-arrow {
  transition: 0.3s;
}
.navbar-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.navbar-icon08 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: #ffffff;
  list-style-position: inside;
}
.navbar-dropdown-list.active {
  display: flex;
  flex-direction: column;
}
.navbar-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text03 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown01 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text06 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown02 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text07 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown03 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text08 {
  color: rgb(0, 0, 0);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown04 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text11 {
  color: rgb(0, 0, 0);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-icon10 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-icon16 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-icon18 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-burger-menu {
  display: none;
}
.navbar-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgba(0, 80, 161, 0.9) 72.00%);
}
.navbar-mobile-menu.active {
  transform: translateX(0%);
}
.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-image1 {
  width: 200px;
  object-fit: cover;
}
.navbar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-link21 {
  align-self: center;
  margin-left: -5px;
}
.navbar-link31 {
  margin-right: var(--dl-space-space-unit);
}
.navbar-thq-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-text14 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: -15px;
  margin-right: mixed;
  vertical-align: middle;
}
.navbar-dropdown-arrow1 {
  transition: 0.3s;
}
.navbar-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.navbar-icon24 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
}
.navbar-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: #ffffff;
  list-style-position: inside;
}
.navbar-dropdown-list1.active {
  display: flex;
  flex-direction: column;
}
.navbar-dropdown05 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text17 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown06 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text20 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown07 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text21 {
  color: #000000;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown08 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text22 {
  color: rgb(0, 0, 0);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown09 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navbar-text25 {
  color: rgb(0, 0, 0);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar-root-class-name {
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 1920px) {
  .navbar-link1, .navbar-link2, .navbar-link3, .navbar-link4, .navbar-link5, .navbar-link6 {
    color: #ffffff;
  }
  .navbar-text {
    font-size: 16px;
    font-style: normal;
    font-family: Noto Sans;
    font-weight: 400;
  }
  .navbar-dropdown-list {
    display: none;
  }
  .navbar-mobile-menu {
    background-color: #67abe0;
    transition: transform 0.3s ease;
  }
  .navbar-mobile-menu.active {
    transform: translateX(0%);
  }
  .navbar-nav {
    position: relative;
  }
  .navbar-links1 {
    gap: var(--dl-space-space-unit);
    position: relative;
  }
  .navbar-link11 {
    color: #000000;
  }
  .navbar-link21 {
    color: #000000;
    margin-left: 0px;
    margin-right: 75px;
  }
  .navbar-link31 {
    color: #000000;
    margin-right: 0px;
  }
  .navbar-dropdown-toggle06 {
    color: #000000;
  }
  .navbar-text14 {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    border-color: #ffffff;
    border-width: 0px;
  }
  .navbar-icon24 {
    fill: #000000;
    flex: 0 0 auto;
  }
  .navbar-dropdown-list1 {
    left: 0%;
    display: none;
    border-color: #000000;
    background-color: #000000;
  }
  .navbar-dropdown-list1.active {
    display: flex;
    flex-direction: column;
  }
  .navbar-dropdown-toggle07 {
    color: #000000;
  }
  .navbar-text17 {
    color: #ffffff;
  }
  .navbar-text20 {
    color: #ffffff;
  }
  .navbar-text21 {
    color: #ffffff;
  }
  .navbar-text22 {
    color: #ffffff;
  }
  .navbar-text25 {
    color: #ffffff;
  }
  .navbar-link51 {
    color: #000000;
  }
  .navbar-link61 {
    color: #000000;
  }
  .navbar-link71 {
    color: #000000;
  }
  .navbar-root-class-name {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 1600px) {
  .navbar-link1, .navbar-link2, .navbar-link3, .navbar-link4, .navbar-link5, .navbar-link6 {
    color: #ffffff;
  }
  .navbar-text {
    font-size: 16px;
    font-style: normal;
    font-family: Noto Sans;
    font-weight: 400;
  }
  .navbar-dropdown-list {
    display: none;
  }
  .navbar-mobile-menu {
    background-color: #67abe0;
    transition: transform 0.3s ease;
  }
  .navbar-mobile-menu.active {
    transform: translateX(0%);
  }
  .navbar-nav {
    position: relative;
  }
  .navbar-links1 {
    gap: var(--dl-space-space-unit);
    position: relative;
  }
  .navbar-link11 {
    color: #000000;
  }
  .navbar-link21 {
    color: #000000;
    margin-left: 0px;
    margin-right: 75px;
  }
  .navbar-link31 {
    color: #000000;
    margin-right: 0px;
  }
  .navbar-dropdown-toggle06 {
    color: #000000;
  }
  .navbar-text14 {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    border-color: #ffffff;
    border-width: 0px;
  }
  .navbar-icon24 {
    fill: #000000;
    flex: 0 0 auto;
  }
  .navbar-dropdown-list1 {
    left: 0%;
    display: none;
    border-color: #000000;
    background-color: #000000;
  }
  .navbar-dropdown-list1.active {
    display: flex;
    flex-direction: column;
  }
  .navbar-dropdown-toggle07 {
    color: #000000;
  }
  .navbar-text17 {
    color: #ffffff;
  }
  .navbar-text20 {
    color: #ffffff;
  }
  .navbar-text21 {
    color: #ffffff;
  }
  .navbar-text22 {
    color: #ffffff;
  }
  .navbar-text25 {
    color: #ffffff;
  }
  .navbar-link51 {
    color: #000000;
  }
  .navbar-link61 {
    color: #000000;
  }
  .navbar-link71 {
    color: #000000;
  }
  .navbar-root-class-name {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 1200px) {
  .navbar-desktop-menu {
    display: none;
  }
  .navbar-burger-menu {
    display: flex;
  }
}
@media(max-width: 991px) {
  .navbar-navbar-interactive {
    flex-direction: row;
  }
  .navbar-root-class-name {
    top: 0px;
    left: 0px;
    position: static;
  }
}
@media(max-width: 767px) {
  .navbar-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-burger-menu {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar-mobile-menu {
    padding: var(--dl-space-space-unit);
    transition: transform 0.3s ease;
  }
  .navbar-mobile-menu.active {
    transform: translateX(0%);
  }
}
