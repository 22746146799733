.csapat-team8 {
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.csapat-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.csapat-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.csapat-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.csapat-slider {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}
.csapat-slider-slide {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image {
  width: 100%;
}
.csapat-content01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text01 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text02 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-card01 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image01 {
  width: 100%;
}
.csapat-content02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title01 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text03 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text04 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card02 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image02 {
  width: 100%;
}
.csapat-content03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title02 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text05 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text06 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-card03 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image03 {
  width: 100%;
}
.csapat-content04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title03 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text07 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text08 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card04 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image04 {
  width: 100%;
}
.csapat-content05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title04 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text09 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text10 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-card05 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-placeholder-image05 {
  width: 100%;
}
.csapat-content06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title05 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text11 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.csapat-text12 {
  overflow: auto;
  font-size: 18px;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-pagination {
  display: block;
}
.csapat-slider1 {
  width: 100%;
  height: auto;
  display: none;
  position: relative;
}
.csapat-slider-slide3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card06 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title06 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text13 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text15 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card07 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title07 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text16 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text18 {
  overflow: hidden;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title08 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text19 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text21 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title09 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text23 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text24 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide7 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title10 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text25 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text27 {
  overflow: hidden;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-slide8 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-slider-slide9 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.csapat-card11 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-card12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-content12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-title11 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.csapat-text29 {
  font-style: normal;
  font-weight: 600;
}
.csapat-text31 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.csapat-slider-pagination1 {
  display: block;
}
@media(max-width: 767px) {
  .csapat-slider {
    display: none;
  }
  .csapat-slider1 {
    display: block;
  }
}
@media(max-width: 479px) {
  .csapat-placeholder-image06 {
    width: 100%;
  }
  .csapat-placeholder-image07 {
    width: 100%;
  }
  .csapat-placeholder-image08 {
    width: 100%;
  }
  .csapat-placeholder-image09 {
    width: 100%;
  }
  .csapat-placeholder-image10 {
    width: 100%;
  }
  .csapat-placeholder-image11 {
    width: 100%;
  }
  .csapat-placeholder-image12 {
    width: 100%;
  }
}
