.home-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text01 {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container1-footer {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: contents;
}
.home-button {
  color: #ffffff;
  border-color: var(--dl-color-theme-primary2);
  border-style: groove;
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-primary2);
}
.home-button1 {
  color: #ffffff;
  border-color: var(--dl-color-theme-primary2);
  border-style: groove;
  border-width: 2px;
  background-color: var(--dl-color-theme-primary2);
}
.allami-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.custom-panel.p-panel {
  margin-bottom: 1.5rem;
}
.custom-panel .p-panel-header {
  background-color: #007bff;
  color: #fff;
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}
.custom-panel .p-panel-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}
.custom-panel .p-panel-content {
  padding: 1.5rem;
  color: #333;
  line-height: 1.6;
}
.bcategory-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.p-accordion .p-accordion-header {
  color: #fff;
  font-weight: bold;
}
.p-accordion .p-accordion-content {
  background-color: #f7f7f7;
  padding: 1rem;
}
.acategory-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.acategory-page h1,
.acategory-page h2,
.acategory-page h3,
.acategory-page h4 {
  color: #333;
  margin-bottom: 1rem;
}
.acategory-page p {
  line-height: 1.6;
}
.acategory-page .p-datatable {
  margin-bottom: 2rem;
}
.my-scrolling-tabs .p-tabview-nav {
  overflow-x: auto;
  white-space: nowrap; 
  -webkit-overflow-scrolling: touch;
}
.my-scrolling-tabs .p-tabview-nav::-webkit-scrollbar {
  height: 6px;
}
.my-scrolling-tabs .p-tabview-nav::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 3px;
}
.aszf-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
  line-height: 1.6;
}
.aszf-toc {
  background-color: #f9f9f9;
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 4px;
}
.aszf-toc h4 {
  margin-bottom: 1rem;
}
.aszf-toc ul {
  list-style: none;
  padding: 0;
}
.aszf-toc li {
  margin-bottom: 0.5rem;
}
.aszf-toc a {
  color: #007bff;
  text-decoration: none;
}
.aszf-toc a:hover {
  text-decoration: underline;
}
.aszf-heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.aszf-subheading {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.contact-logo {
  max-width: 100%;
  height: auto;
}
.col-12 {
  flex: 0 0 100%;
}
@media (min-width: 992px) {
  .lg\:col-6 {
    flex: 0 0 48%;
  }
}
.facebook-iframe-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
}
.p-panel {
  margin-bottom: 1.5rem;
}
.data-policy-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  line-height: 1.6;
}
.dp-title {
  font-size: 2rem;
  font-weight: bold;
}
.dp-toc {
  background: #f9f9f9;
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 6px;
}
.dp-toc ul {
  list-style: none;
  padding-left: 0;
}
.dp-toc ul li a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.2s;
}
.dp-toc ul li a:hover {
  text-decoration: underline;
}
.dp-panel {
  margin-bottom: 1.5rem;
}
.dp-content {
  margin: 0;
  padding: 1rem 0;
}
.ako-vsm-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.quarter-block {
  margin-bottom: 1.5rem;
}
.quarter-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.vsm-image {
  width: 100%;
  max-width: 800px;
  height: auto;
}
.ako-image {
  width: 100%;
  max-width: 800px;
  height: auto;
}
ul.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
.mt-5 {
  margin-top: 3rem !important;
}
.home-video {
  width: 1679px;
  height: 927px;
}
.home-text04 {
  font-size: 25px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 700;
  margin-left: 160px;
}
.home-container2 {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon {
  top: 52px;
  left: -11px;
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: 160px;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.home-text08 {
  top: -28px;
  left: 16px;
  position: absolute;
  font-size: 30px;
  align-self: stretch;
  margin-left: 160px;
}
.home-button2 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  width: 339px;
  height: 79px;
  align-self: flex-start;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-text09 {
  font-size: 25px;
}
@media(max-width: 1600px) {
  .home-text01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-button {
    color: #ffffff;
    border-color: var(--dl-color-theme-primary2);
    border-style: groove;
    border-width: 2px;
    margin-right: var(--dl-space-space-twounits);
    animation-name: pulse;
    animation-delay: 0s;
    background-color: var(--dl-color-theme-primary2);
    animation-duration: 500ms;
    animation-direction: normal;
    animation-iteration-count: 10000000;
    animation-timing-function: ease;
  }
  .home-button1 {
    color: #ffffff;
    border-color: var(--dl-color-theme-primary2);
    border-style: groove;
    border-width: 2px;
    animation-name: pulse;
    animation-delay: 0s;
    background-color: var(--dl-color-theme-primary2);
    animation-duration: 500ms;
    animation-direction: normal;
    animation-iteration-count: 10000000;
    animation-timing-function: ease;
  }
  .home-video {
    width: 100%;
    height: 709px;
  }
  .home-text04 {
    margin-left: 100px;
  }
  .home-container2 {
    width: 100%;
  }
  .home-icon {
    top: 40px;
    left: -5px;
    margin-left: 100px;
  }
  .home-text08 {
    top: -40px;
    left: 22px;
    margin-left: 100px;
  }
}
@media(max-width: 1200px) {
  .home-video {
    height: 581px;
  }
  .home-text04 {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    margin-left: 0px;
  }
  .home-icon {
    top: 36px;
    left: -99px;
  }
  .home-text08 {
    top: -44px;
    left: -72px;
  }
  .home-button2 {
    align-self: center;
    margin-left: 0px;
  }
}
@media(max-width: 991px) {
  .home-video {
    height: 496px;
    padding-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-text {
    font-size: 30px;
    text-align: center;
  }
  .home-video {
    height: 299px;
  }
}
@media(max-width: 479px) {
  .home-button2 {
    width: 282px;
    height: 97px;
    align-self: center;
    margin-right: 0px;
  }
}

.header{
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0px 15px 10px -15px black;
}

.col a.logo{
  text-decoration: none;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

.col-12{
  padding-bottom: 20px;
}


.big-title{
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
}

.btn:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.button-40:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.content-groups{
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.card1{
  width: 250px;
  height: 400px;
  float: left;
  border: 1px solid gray;
  margin-right: 20px;
  margin-bottom: 30px;
}

.card2{
  width: 250px;
  height: 400px;
  float: left;
  border: 1px solid gray;
  margin-right: 20px;
  margin-bottom: 30px;
}

.card3{
  width: 250px;
  height: 400px;
  overflow: auto;
  border: 1px solid gray;
  margin-bottom: 30px;
}

.card-body{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-img-top2{
  width: 248px;
  height: 165px;
}

.card-text{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.bi{
  padding-right: 10px;
}

.fejlesztes_alatt{
  font-size: 50px;
  text-align: center;
  color: red;
}

.img-cars{
  display: block;
  margin: 0 auto;
  width: 90%;
  filter: drop-shadow(0 0 10px black);
}

.car-title{
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: bold;
}

.per-day{
  margin-bottom: 20px;
}

.bold-price{
  font-weight: bold;
}

.rent-now{
  padding: 5px 15px;
  background-color: white;
  box-shadow: 0 0 3px gray;
  color: black;
  cursor: pointer;
  transition: all 0.5s;
  border: none;
}

.rent-now:hover{
  background-color: black;
  color: white;
}

.rent-link{
  color: black;
  text-decoration: none;
}


.rent-link:hover{
  color: white;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-title{
  font-size: 40px;
  text-transform: uppercase;
  text-shadow: 0 0 2px black;
}

.form-container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
}

.form-groups{
  width: 300px;
}

.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-title{
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.form-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
  padding: 10px 5px;
}

.input{
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}


.login-button{
  display: block;
  margin: 0 auto;
  width: max-content;
}

.button-40 {
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.button-40:hover {
  background-color: #374151;
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.form-link{
  font-weight: bold;
  text-decoration: none;
  color: blue;
}

.form-link:hover{
  text-decoration: underline;
}


.logout{
  text-transform: uppercase;
  margin-left: 15px;
  font-weight: bold;
  cursor: pointer;
}


.logout:hover{
  text-decoration: underline;
}


.car-title2{
  text-align: center;
  margin: 100px 0 100px 0;
  font-size: 40px;
}

.car-row{
  display: flex;
}

.car-col{
  flex: 1;
  margin-bottom: 100px;
}

.car-group{
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.car-subtitle{
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: rgb(140, 140, 140);
}

.car-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.car-info span{
  margin-bottom: 5px;
  font-size: 40px;
}

.car-image{
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-img{
  width: 600px;
  height: auto;
  filter: drop-shadow(0 0 3px black);
}

.total{
  margin-top: 25px;
}

.total-title{
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-flex{
  display: flex;
  padding: 0 10px;
}

.flex-1 {
  flex: 1;
}

.flex-2{
  flex: 2;
}

.div-filter{
  margin-bottom: 40px;
}

.filter-btns{
  display: flex;
  justify-content: center;
}

.btn-type{
  padding: 5px 15px;
  background-color: white;
  box-shadow: 0 0 4px black;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
}

.btn-type:hover{
  background-color: gray;
}

.search{
  height: 30px;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 15px;
  color: gray;
  border-bottom: 1px solid black;
}

.text-center{
  left:0;
  bottom:0;
  right:0;
}

.main_title{
  color: black;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration:underline;
  padding: 0 1.2rem;
  height: 100%;
  font-size: 40px;
  text-align: left;
  text-shadow: 0 0 4px black;
}


body {
  color: rgb(var(--bs-tertiary-color-rgb));
}



.carousel {
  margin-bottom: 4rem;
}
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

.carousel-item {
  height: 32rem;
}

.oktato1-szabi{
  animation: float 6s ease-in-out infinite;
}

.oktato2-zsolt{
  animation: float 6s ease-in-out infinite;
}

.oktato3-daniel{
  animation: float 6s ease-in-out infinite;
}

.oktato4-levente{
  animation: float 6s ease-in-out infinite;
}

.ugyintezo-adel{
  animation: float 6s ease-in-out infinite;
}

.phone-number {
  font-size: 16px;
}

.phone-icon-container {
  display: flex;
  align-items: center;
}

.iroda-kep2{
    width: 100%;
}

.col a.login{
  margin-left: 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.col a.login:hover{
  text-decoration: underline;
}

.calendar {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.apply-online{
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-online-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}

.name{
  text-transform: uppercase;
  font-weight: bold;
}

.App{
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}

.main{
  flex-grow: 1;
}

.all-prices{
  background: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgba(0, 80, 161, 0.9) 66.00%);
}

.prices-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.bank-info {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.price-text {
  font-size: 1.4rem;
  color: #2196f3;
  margin-top: 1rem;
}

.p-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
}

.social-icons a {
  color: #333;
  text-decoration: none;
  font-size: 24px;
  margin-right: 20px; /* Add this rule to create space between icons */
}

.social-icons a:last-child {
  margin-right: 0; /* Remove margin from the last icon to prevent extra spacing on the right side */
}

.ugyintezo{
  padding-bottom: 10px;
}

.facebook{
  color: black;
}

.instagram{
  color: black;
}

/* .footer{
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
} */

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.slider{
  display: flex;

}

.navbar-brand{
  padding-right: 350px;
}


.main-picture{
  padding-bottom: 50px;
  padding-top: 20px;
}

.left{
  display: flex;
  align-items: center;
  justify-content: center;
}

.title{
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 5px black;
}

.card{
  width: 18rem;
}

.card2{
  width: 18rem;
}

.navbar{
  position: relative;
  display: flex;
  padding: 10px 40px;
  background: linear-gradient(to right, black, #3498db);
}

.low-title{
  margin-left: 200px;
  margin-right: 200px;
}

.low-title-content1{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 2px black;
  text-decoration: underline;
}

.low-title-content2{
  line-height: 200%;
}

.low-title-content3{
  line-height: 200%;
  padding-bottom: 10px;
}

.low-titles{
  text-shadow: 0 0 1px black;
  font-size: 23px;
}

.low-titles-elmeleti{
  text-shadow: 0 0 1px black;
  font-size: 23px;
}

.low-titles-prices{
  text-shadow: 0 0 1px black;
  text-decoration: underline;
}

.low-titles-aszf{
  padding-top: 20px;
  text-shadow: 0 0 1px black;
  text-decoration: underline;
}

.low-titles2{
  font-style: italic;
}

.bcat2{
  font-style: italic;
  font-size: 20px;
}

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}



.featurette-divider {
  margin: 6rem 0;
}

.featurette-heading {
  letter-spacing: -.05rem;
}


.bcat{
    font-size: 30px;
    text-decoration: underline;
    font-weight:500;
}

.contact{
  text-shadow: 0 0 2px black;
  font-size: 30px;
  text-decoration: underline;
}

.teachers-page {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.teacher-card {
  border: 1px solid #ddd;
  transition: box-shadow 0.3s;
}

.teacher-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.teacher-image-container img {
  border-radius: 8px;
}

.text-muted {
  color: #888;
}

.oktato1{
  font-size: 30px;
  font-weight: bold;
  text-shadow: 5px 5px 10px gray;
}

.oktato2{
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 5px 5px 10px gray;
}

.naptar_main{
  display: flex;
  justify-content: center;
}

.bcategory{
  font-size: 30px;
  margin-left: 200px;
  margin-right: 200px;
  font-weight: bold;
  padding-bottom: 40px;
  padding-top: 30px;
}

.oktato1-beosztas{
  text-decoration: underline;
  font-size: 20px;
  text-shadow: 0 0 10px black;
}

.nincs-rejtett{
  font-size: 25px;
  font-weight: bold;
}

.right{
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}

.main-title{
  font-size: 3rem;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.content{
  margin: 100px 0;
}

.turelem{
  font-size: 30px;
}

.turelem2{
  font-size: 30px;
  padding-left: 30px;
}

.oktatok{
  padding-top: 15px;
}

.apply-button{
  position: relative;
  text-align: center;
}

@keyframes moveBackground {
  0% { background-position: 0 0; }
  100% { background-position: 100% 0; }
}

@media only screen and (max-width: 1920px) and (min-width: 1680px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 1100px;
    height: 600px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
  }
  .title{
    font-size: 30px;
  }
  .focim{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 500px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 1000px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1550px;
  height: 1050px;
  margin-left: 80px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.logo-kep{
  width: 370px;
  height: 200px;
  margin-bottom: 20px;
}
.red-button{
  width: 350px;
  height: 100px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.balaton-video{
  height: 700px;
  width: 1250px;
}
.turelem{
  font-size: 40px;
}
}

@media only screen and (max-width: 1680px) and (min-width: 1440px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 1100px;
    height: 600px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .focim{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 300px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 1000px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1350px;
  height: 850px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.logo-kep{
  width: 370px;
  height: 200px;
  margin-bottom: 20px;
}
.red-button{
  width: 350px;
  height: 100px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.balaton-video{
  height: 700px;
  width: 1250px;
}
.turelem{
  font-size: 40px;
}
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 1100px;
    height: 600px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .focim{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 200px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 1000px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1350px;
  height: 850px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.logo-kep{
  width: 370px;
  height: 150px;
  margin-bottom: 20px;
}
.red-button{
  width: 350px;
  height: 100px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.balaton-video{
  height: 600px;
  width: 1050px;
}
.turelem{
  font-size: 40px;
}
}

@media only screen and (max-width: 1367px) and (min-width: 1280px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 1100px;
    height: 600px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 50px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 100px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 1000px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1250px;
  height: 800px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.logo-kep{
  width: 370px;
  height: 150px;
  margin-bottom: 20px;
}
.red-button{
  width: 350px;
  height: 100px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 650px;
  width: 1100px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 1280px) and (min-width: 1100px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 1050px;
    height: 530px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 100px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 1000px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1100px;
  height: 750px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.logo-kep{
  width: 370px;
  height: 150px;
  margin-bottom: 20px;
}
.red-button{
  width: 350px;
  height: 100px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 550px;
  width: 950px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 1100px) and (min-width: 1024px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 950px;
    height: 480px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 10px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  font-size: 30px;
}
.animacio{
  width: 950px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 1000px;
  height: 700px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 370px;
  height: 450px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 900px;
}
.AKO_kep{
  width: 900px;
}
.logo-kep{
  width: 370px;
  height: 150px;
  margin-bottom: 20px;
}
.red-button{
  width: 300px;
  height: 80px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 550px;
  width: 950px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 1024px) and (min-width: 993px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 950px;
    height: 500px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 30px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 430px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 900px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 995px;
  height: 680px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.home-nev{
  font-size: 35px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 900px;
}
.AKO_kep{
  width: 900px;
}
.logo-kep{
  width: 370px;
  height: 150px;
}
.red-button{
  width: 300px;
  height: 80px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 500px;
  width: 850px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 993px) and (min-width: 991px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 890px;
    height: 480px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 30px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 320px;
  height: 420px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 800px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 880px;
  height: 620px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 300px;
  height: 400px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 800px;
}
.AKO_kep{
  width: 800px;
}
.logo-kep{
  width: 370px;
  height: 150px;
}
.red-button{
  width: 300px;
  height: 80px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 700px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 991px) and (min-width: 913px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 890px;
    height: 480px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 45px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  padding-right: 30px;
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 320px;
  height: 420px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  padding-left: 80px;
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 800px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 880px;
  height: 620px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.social-icons{
  display: flex;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 800px;
}
.AKO_kep{
  width: 800px;
}
.logo-kep{
  width: 370px;
  height: 150px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.red-button{
  width: 300px;
  height: 80px;
  margin-left: 50px;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 700px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 913px) and (min-width: 821px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 800px;
    height: 430px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main-title{
    font-size: 45px;
  }
  .home-telefonszam{
    font-size: 25px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 500px;
  height: 200px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 320px;
  height: 420px;
}
.home-tema1{
  font-size: 30px;
}
.home-tema2{
  padding-left: 80px;
  font-size: 30px;
}
.home-tema3{
  margin-right: 50px;
  font-size: 30px;
}
.animacio{
  width: 700px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 30px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 810px;
  height: 600px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: 20px;
}
.float-end{
  padding-top: 15px;
}
.copy{
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.home-nev{
  font-size: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.bcategory{
  font-size: 35px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 400px;
  height: 550px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 700px;
}
.AKO_kep{
  width: 700px;
}
.logo-kep{
  width: 370px;
  height: 150px;
  padding-bottom: 20px;
}
.social-icons{
  display: flex;
}
.red-button{
  width: 300px;
  height: 80px;
  margin-left: 50px;
}
.logo-div{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 700px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 821px) and (min-width: 767px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 760px;
    height: 420px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main-title{
    font-size: 40px;
  }
  .home-telefonszam{
    font-size: 20px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  padding-left: 30px;
  width: 310px;
  height: 380px;
}
.utalni{
  padding-left: 30px;
}
.home-tema1{
  padding-left: 0px;
}
.megtalaltad{
  padding-left: 0px;
}
.home-tema3{
  margin-right: 50px;
}
.animacio{
  width: 620px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 25px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 720px;
  height: 530px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 370px;
  height: 500px;
}
.home-nev{
  font-size: 30px;
}
.home-tema2{
  padding-left: 40px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 370px;
  height: 500px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 370px;
  height: 500px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 370px;
  height: 500px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
  width: 370px;
  height: 500px;
}
.social-icons{
  display: flex;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.VSM_kep{
  width: 650px;
}
.AKO_kep{
  width: 650px;
}
.logo-kep{
  width: 370px;
  height: 150px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 530px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 767px) and (min-width: 730px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 720px;
    height: 400px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 20px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 450px;
}
.home-tema3{
  margin-right: 50px;
}
.animacio{
  width: 670px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 25px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 720px;
  height: 530px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.home-nev{
  font-size: 30px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 350px;
  height: 430px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 600px;
}
.AKO_kep{
  width: 600px;
}
.logo-kep{
  margin-left: 30px;
  width: 370px;
  height: 160px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 530px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 730px) and (min-width: 675px) {
  .main {
    font-size: calc(12px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 650px;
    height: 360px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main-title{
    font-size: 40px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 20px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 450px;
}
.home-tema3{
  margin-right: 50px;
}
.animacio{
  width: 625px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 25px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 670px;
  height: 500px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.home-nev{
  font-size: 30px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 600px;
}
.AKO_kep{
  width: 600px;
}
.logo-kep{
  margin-left: 30px;
  width: 370px;
  height: 160px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 40px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 400px;
  width: 530px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 675px) and (min-width: 600px) {
  .main {
    font-size: calc(11px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 580px;
    height: 340px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 20px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 400px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 450px;
}
.home-tema3{
  margin-right: 50px;
}
.animacio{
  width: 550px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 25px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 590px;
  height: 470px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.home-nev{
  font-size: 30px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 340px;
  height: 420px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 550px;
}
.AKO_kep{
  width: 550px;
}
.logo-kep{
  margin-left: 30px;
  width: 370px;
  height: 160px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 38px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 300px;
  width: 500px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 600px) and (min-width: 541px) {
  .main {
    font-size: calc(11px + 1.5vmin);
  }
  .main-picture{
    padding-left: 20px;
    width: 530px;
    height: 320px;
  }
  .navbar-picture{
    width: 300px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  .main-oktatok{
    font-size: 35px;
  }
  .home-telefonszam{
    font-size: 20px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 450px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 350px;
  height: 400px;
}
.home-tema3{
  margin-right: 50px;
}
.animacio{
  width: 490px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
  font-size: 20px;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
  font-size: 25px;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 545px;
  height: 450px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 28px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.home-nev{
  font-size: 30px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 500px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 550px;
}
.AKO_kep{
  width: 550px;
}
.logo-kep{
  margin-left: 30px;
  width: 370px;
  height: 160px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.social-icons{
  display: flex;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 38px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 300px;
  width: 480px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 541px) and (min-width: 500px) {
  .main {
    font-size: calc(11px + 1.5vmin);
  }
  .main-picture{
    padding-left: 10px;
    width: 490px;
    height: 290px;
  }
  .navbar-picture{
    width: 300px;
    height: 130px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 380px;
  height: 460px;
}
.animacio{
  width: 440px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 490px;
  height: 365px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato2-zsolt{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato3-daniel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 320px;
  height: 400px;
}
.ugyintezo-adel{
  margin-left: 12px;
  width: 350px;
  height: 450px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 500px;
}
.AKO_kep{
  width: 500px;
}
.logo-kep{
  margin-left: 30px;
  width: 370px;
  height: 160px;
}
.red-button{
  width: 300px;
  height: 80px;
}
.social-icons{
  display: flex;
}
.apply2{
  position: relative;
  text-align: center;
}
.title{
  font-size: 38px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 250px;
  width: 450px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 500px) and (min-width: 460px) {
  .main {
    font-size: calc(11px + 1.5vmin);
  }
  .main-picture{
    padding-left: 10px;
    width: 450px;
    height: 270px;
  }
  .navbar-picture{
    width: 280px;
    height: 120px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .focim{
    font-size: 40px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 400px;
  height: 460px;
}
.animacio{
  width: 400px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 455px;
  height: 350px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
}
.oktato2-zsolt{
  margin-left: 12px;
}
.oktato3-daniel{
  margin-left: 12px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 460px;
}
.AKO_kep{
  width: 460px;
}
.logo-kep{
  width: 340px;
  height: 160px;
}
.red-button{
  width: 200px;
  height: 100px;
}
.nav-link{
  padding: 0px;
}
.social-icons{
  display: flex;
}
.title{
  font-size: 35px;
}
.featurette-heading {
  font-size: 35px;
}
.balaton-video{
  height: 240px;
  width: 430px;
}
.turelem{
  font-size: 35px;
}
}

@media only screen and (max-width: 460px) and (min-width: 415px) {
  .main {
    font-size: calc(11px + 1.5vmin);
  }
  .main-picture{
    padding-left: 10px;
    width: 410px;
    height: 260px;
  }
  .navbar-picture{
    width: 220px;
    height: 100px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 360px;
  height: 460px;
}
.animacio{
  width: 375px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 410px;
  height: 330px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.float-end{
  padding-left: 9px;
  padding-top: 15px;
}
.copy{
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.phone-icon-container{
  padding-top: 20px;
}
.oktato1-szabi{
  margin-left: 12px;
}
.oktato2-zsolt{
  margin-left: 12px;
}
.oktato3-daniel{
  margin-left: 12px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 300px;
  height: 380px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
/* .footer-logo{
  width: fit-content;
} */
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.VSM_kep{
  width: 420px;
}
.AKO_kep{
  width: 420px;
}
.logo-kep{
  width: 340px;
  height: 160px;
}
.red-button{
  width: 200px;
  height: 100px;
}
.nav-link{
  padding: 0px;
}
.social-icons{
  display: flex;
}
.title{
  font-size: 33px;
}
.featurette-heading {
  font-size: 33px;
}
.balaton-video{
  height: 220px;
  width: 360px;
}
.turelem{
  font-size: 33px;
}
}

@media only screen and (max-width: 415px) and (min-width: 394px) {
  .main {
    font-size: calc(10px + 1.5vmin);
  }
  .main-picture{
    padding-left: 10px;
    width: 390px;
    height: 250px;
  }
  .navbar-picture{
    width: 200px;
    height: 90px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 30px;
  }
  .main-title{
    font-size: 40px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 375px;
  height: 170px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */

/* .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 340px;
  height: 450px;
}
.animacio{
  width: 355px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
}
.oktato3{
  padding: 1rem;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
  width: 400px;
  height: 280px;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.nav-link{
  padding: 0px;
}
.social-icons{
  display: flex;
}
.float-end{
  padding-left: 9px;
}
.copy{
  padding-left: 9px;
}
.oktato1-szabi{
  margin-left: 12px;
}
.oktato2-zsolt{
  margin-left: 12px;
}
.oktato3-daniel{
  margin-left: 12px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 12px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.VSM_kep{
  width: 380px;
}
.AKO_kep{
  width: 380px;
}
.logo-kep{
  width: 340px;
  height: 160px;
}
.red-button{
  width: 200px;
  height: 100px;
}
.title{
  font-size: 30px;
}
.featurette-heading {
  font-size: 25px;
}
.balaton-video{
  height: 220px;
  width: 340px;
}
.turelem{
  font-size: 25px;
}
}

@media only screen and (max-width: 394px) and (min-width: 350px) {
  .main {
    font-size: calc(8px + 1.5vmin);
  }
  .main-picture{
    padding-left: 10px;
    width: 340px;
    height: 220px;
  }
  .navbar-picture{
    width: 172px;
    height: 80px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main-title{
    font-size: 30px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 300px;
  height: 130px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 290px;
  height: 320px;
}
.iroda-kep2{
  width: 345px;
  height: 250px;
}
.animacio{
  width: 290px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
  font-size: 20px;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
  font-size: 20px;
}
.oktato3{
  padding: 1rem;
  font-size: 20px;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.nav-link{
  padding: 0px;
}
.social-icons{
  display: flex;
}
.float-end{
  padding-left: 0px;
}
.phone-icon-container{
  padding-left: 0px;
}
.copy{
  padding-left: 9px;
}
.home-tema1{
  font-size: 25px;
}
.home-tema2{
  font-size: 25px;
}
.oktato1-szabi{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.oktato2-zsolt{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.oktato3-daniel{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 260px;
  height: 340px;
}
.ugyintezo-adel{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.main-oktatok{
  font-size: 25px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
.low-titles-elmeleti{
  padding: 1rem;
}
.oktato4-levente{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.VSM_kep{
  width: 320px;
}
.AKO_kep{
  width: 320px;
}
.oktato4-levente{
  width: 230px;
  height: 310px;
}
.logo-kep{
  width: 300px;
  height: 150px;
}
.red-button{
  width: 200px;
  height: 100px;
}
.title{
  font-size: 30px;
}
.featurette-heading {
  font-size: 25px;
}
.balaton-video{
  height: 220px;
  width: 280px;
}
.turelem{
  font-size: 25px;
}
}

@media only screen and (max-width: 350px) and (min-width: 280px) {
  .main {
    font-size: calc(8px + 1.5vmin);
  }
  .main-picture{
    width: 280px;
    height: 200px;
  }
  .navbar-picture{
    width: 145px;
    height: 70px;
  }
  .navbar{
    padding-left: 1px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .main-title{
    font-size: 30px;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 300px;
  height: 130px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 230px;
  height: 320px;
}
.iroda-kep2{
  width: 280px;
  height: 230px;
}
.animacio{
  width: 240px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.low-titles-elmeleti{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
  font-size: 20px;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
  font-size: 20px;
}
.oktato3{
  padding: 1rem;
  font-size: 20px;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.nav-link{
  padding: 0px;
}
.float-end{
  padding-left: 0px;
}
.phone-icon-container{
  padding-left: 0px;
}
.copy{
  padding-left: 9px;
}
.home-tema1{
  font-size: 25px;
}
.home-tema2{
  font-size: 25px;
}
.oktato1-szabi{
  width: 230px;
  height: 300px;
}
.oktato2-zsolt{
  width: 230px;
  height: 300px;
}
.oktato3-daniel{
  width: 230px;
  height: 300px;
}
.oktato4-levente{
  width: 230px;
  height: 300px;
}
.oktato1-szabi-oktatok{
  margin-left: 10px;
  width: 240px;
  height: 330px;
}
.oktato2-zsolt-oktatok{
  margin-left: 10px;
  width: 240px;
  height: 330px;
}
.oktato3-daniel-oktatok{
  margin-left: 10px;
  width: 240px;
  height: 330px;
}
.ugyintezo-adel{
  margin-left: 10px;
  width: 230px;
  height: 300px;
}
.main-oktatok{
  font-size: 25px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
.naptar_main{
  display: none;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.VSM_kep{
  width: 260px;
}
.AKO_kep{
  width: 260px;
}
.oktato4-levente{
  width: 230px;
  height: 310px;
}
.logo-kep{
  width: 220px;
  height: 150px;
}
.red-button{
  width: 200px;
  height: 100px;
}
.title{
  font-size: 30px;
}
.calendar{
  display: none;
}
.featurette-heading {
  font-size: 25px;
}
.balaton-video{
  height: 150px;
  width: 220px;
}
.turelem{
  font-size: 25px;
}
}

@media only screen and (max-width: 280px) {
  .main {
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-size: calc(8px + 1.5vmin);
  }
  .navbar-picture{
    width: 142px;
    height: 70px;
  }
  .title{
    font-size: 30px;
  }
  .main-picture{
    width: 260px;
    height: 190px;
  }
  .main-title{
    font-size: 30px;
  }
  .carousel img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  html,
  body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.nav-link{
  padding: 0px;
}
.low-title{
  display:contents;
  max-width: 410px;
  padding: 1rem;
}
.oktatok{
  width: 400px;
  height: 270px;
  padding: 1rem;
}
.logo{
  width: 260px;
  height: 130px;
  padding-right: 10px;
}
/* .footer{
  background-color: #758283;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  bottom: 0;
} */
.navbar-brand{
  display: flex;
}
.navbar-collapse {
  display: flex;
}
.iroda-kep{
  width: 220px;
  height: 270px;
}
.logo-kep{
  width: 220px;
  height: 150px;
}
.iroda-kep2{
  width: 260px;
  height: 240px;
}
.animacio{
  width: 250px;
}
.low-titles-prices{
  padding: 1rem;
}
.low-title-content2{
  padding: 1rem;
}
.nincs-rejtett{
  padding: 1rem;
}
.oktato1{
  padding: 1rem;
  font-size: 20px;
}
.oktato1-beosztas{
  padding: 1rem;
}
.oktato2{
  padding: 1rem;
  font-size: 20px;
}
.oktato3{
  padding: 1rem;
  font-size: 20px;
}
.low-titles{
  padding: 1rem;
}
.low-title-content3{
  padding: 1rem;
}
.megtalaltad{
  font-size: 20px;
}
.bcat{
  padding: 1rem;
}
.bcat2{
  padding: 1rem;
}
.low-titles-aszf{
  padding: 1rem;
}
.contact{
  padding: 1rem;
}
.form{
  padding: 1rem;
}
.iroda-kep2{
  padding: 1rem;
}
.map{
  padding: 1rem;
}
.low-titles2{
  padding: 1rem;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.social-icons{
  padding-left: 100px;
  padding-bottom: 20px;
}
.float-end{
  padding-left: 9px;
}
.phone-icon-container{
  padding-left: 9px;
}
.copy{
  padding-left: 9px;
}
.home-tema2{
  font-size: 20px;
}
.home-tema1{
  font-size: 20px;
}
.home-tema3{
  font-size: 20px;
}
.oktato1-szabi{
  width: 220px;
  height: 300px;
}
.oktato1-szabi-oktatok{
  width: 240px;
  height: 330px;
}
.oktato2-zsolt-oktatok{
  width: 240px;
  height: 330px;
}
.oktato3-daniel-oktatok{
  width: 240px;
  height: 330px;
}
.oktato2-zsolt{
  width: 220px;
  height: 300px;
}
.oktato3-daniel{
  width: 220px;
  height: 300px;
}
.oktato4-levente{
  width: 220px;
  height: 300px;
}
.ugyintezo-adel{
  width: 220px;
  height: 300px;
}
.bcategory{
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}
.naptar_main{
  display: none;
}
.main-oktatok{
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.VSM_kep{
  width: 260px;
}
.AKO_kep{
  width: 260px;
}
.balaton-video{
  height: 150px;
  width: 220px;
}
.turelem{
  font-size: 25px;
}
.featurette-heading {
  font-size: 20px;
}
}

@media (min-width: 768px) {
  .button-40 {
    padding: .75rem 1.5rem;
  }
}




