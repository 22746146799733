.carousel-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: row-reverse;
  justify-content: center;
}
.carousel-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.carousel-section-title {
  gap: 24px;
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.carousel-text {
  text-align: center;
}
.carousel-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.carousel-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.carousel-slider {
  width: 100%;
  height: 770px;
  display: inline-block;
}
.carousel-slider-slide {
  height: calc(100% - 20px);
  display: flex;
}
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-slider-slide1 {
  height: calc(100% - 20px);
  display: flex;
}
.carousel-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-slider-slide2 {
  height: calc(100% - 20px);
  display: flex;
}
.carousel-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-slider-slide3 {
  height: calc(100% - 20px);
  display: flex;
}
.carousel-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-slider-pagination {
  display: block;
}

@media(max-width: 1600px) {
  .carousel-text {
    font-size: 20px;
  }
}
@media(max-width: 991px) {
  .carousel-content {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .carousel-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .carousel-slider {
    height: 550px;
  }
}
@media(max-width: 479px) {
  .carousel-slider {
    height: 440px;
  }
}
